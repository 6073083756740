import { UiBaseSpotMarketWithToken } from '@injectivelabs/sdk-ui-ts'
import BaseMessageParser from './BaseMessageParser'
import { getCancelledOrder } from './helper'
import {
  MsgBatchCancelSpotOrdersParsed,
  CancelledOrder
} from '@/types/messages'
import { indexerSpotApi } from '@/app/Services'

export class MsgBatchCancelSpotOrders extends BaseMessageParser {
  async parse(): Promise<MsgBatchCancelSpotOrdersParsed> {
    const { message } = this

    const markets = (await Promise.all(
      message.data.map(
        async (order: Record<string, any>) =>
          await indexerSpotApi.fetchMarket(order.market_id)
      )
    )) as UiBaseSpotMarketWithToken[]

    const orders = (await Promise.all(
      message.data.map((order: Record<string, any>, index: number) =>
        getCancelledOrder(markets[index], order)
      )
    )) as CancelledOrder[]

    return {
      orders,
      sender: message.sender
    }
  }
}
