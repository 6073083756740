import BaseMessageParser from './BaseMessageParser'
import { MsgUnjailParsed } from '@/types/messages'

export class MsgUnjail extends BaseMessageParser {
  parse(): Promise<MsgUnjailParsed> {
    const { message } = this
    const { validator_addr: validator } = message

    return Promise.resolve({
      validator
    })
  }
}
