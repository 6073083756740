import BaseMessageParser from './BaseMessageParser'
import { DefaultMessageParsed } from '@/types/messages'

export class DefaultMessage extends BaseMessageParser {
  parse(): Promise<DefaultMessageParsed> {
    // hide unsupported messageType on Transaction details summary section

    return Promise.resolve({
      noSummary: true,
      ...this.message
    })
  }
}
