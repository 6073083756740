import { BigNumberInWei } from '@injectivelabs/utils'
import BaseMessageParser from './BaseMessageParser'
import { formatNumber } from './helper'
import { MsgRecvPacketParsed, MsgBase } from '@/types/messages'
import { denomClient } from '@/app/Services'

export class MsgRecvPacket extends BaseMessageParser {
  async parse(): Promise<MsgRecvPacketParsed | MsgBase> {
    const { message } = this

    const { packet, signer } = message
    const decodedPacketData = JSON.parse(
      Buffer.from(packet.data, 'base64').toString('utf-8')
    )

    const { amount, denom, sender, receiver } = decodedPacketData

    if (!amount && !denom && !sender && !receiver) {
      return {
        signer,
        noSummary: true
      }
    }

    const token = await denomClient.getDenomToken(denom)

    return {
      sender,
      signer,
      receiver,
      amount: formatNumber(
        new BigNumberInWei(amount || 0).toBase(token ? token.decimals : 18)
      ),
      symbol: token ? token.symbol : ''
    }
  }
}
