import { BigNumberInWei } from '@injectivelabs/utils'
import BaseMessageParser from './BaseMessageParser'
import { formatNumber } from './helper'
import { MsgTransferParsed } from '@/types/messages'
import { denomClient } from '@/app/Services'

export class MsgTransfer extends BaseMessageParser {
  async parse(): Promise<MsgTransferParsed> {
    const { message } = this
    const { token: tokenAmount, sender, receiver: toAddress } = message

    const token = await denomClient.getDenomToken(tokenAmount.denom)

    return Promise.resolve({
      sender,
      amount: formatNumber(
        new BigNumberInWei(tokenAmount.amount || 0).toBase(
          token ? token.decimals : 18
        )
      ),
      receiver: toAddress,
      symbol: token ? token.symbol : ''
    })
  }
}
