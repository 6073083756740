import { PerpetualMarket, ExpiryFuturesMarket } from '@injectivelabs/sdk-ts'
import BaseMessageParser from './BaseMessageParser'
import { getCancelledOrder } from './helper'
import {
  MsgBatchCancelDerivativeOrdersParsed,
  CancelledOrder
} from '@/types/messages'
import { indexerDerivativesApi } from '@/app/Services'

export class MsgBatchCancelDerivativeOrders extends BaseMessageParser {
  async parse(): Promise<MsgBatchCancelDerivativeOrdersParsed> {
    const { message } = this

    const markets = (await Promise.all(
      message.data.map(
        async (order: Record<string, any>) =>
          await indexerDerivativesApi.fetchMarket(order.market_id)
      )
    )) as Array<PerpetualMarket | ExpiryFuturesMarket>

    const orders = (await Promise.all(
      message.data.map(
        async (order: Record<string, any>, index: number) =>
          await getCancelledOrder(markets[index], order)
      )
    )) as CancelledOrder[]

    return {
      orders,
      sender: message.sender
    }
  }
}
