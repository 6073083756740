import { BigNumberInBase, BigNumberInWei } from '@injectivelabs/utils'
import { PerpetualMarket, ExpiryFuturesMarket } from '@injectivelabs/sdk-ts'
import { Token } from '@injectivelabs/token-metadata'
import {
  UiBaseSpotMarketWithToken,
  UiBaseSpotMarket
} from '@injectivelabs/sdk-ui-ts'
import { Order, CancelledOrder, AllCancelledOrder } from '@/types/messages'
import { UI_DEFAULT_DECIMAL_PLACES } from '@/app/utils/constants'
import { denomClient } from '@/app/Services'

export const formatNumber = (price: BigNumberInBase): string => {
  if (price.lt(new BigNumberInBase(0.000001))) {
    return '<0.000001'
  }

  if (price.lt(new BigNumberInBase(0.00001))) {
    return '<0.00001'
  }

  if (price.lt(new BigNumberInBase(0.0001))) {
    return '<0.0001'
  }

  if (price.lt(new BigNumberInBase(0.001))) {
    return '<0.001'
  }

  const decimalPlaces =
    price.toNumber() % 1 !== 0 ? price.toFixed().split('.')[1].length : 0

  // return 3 or less decimal places
  return price.toFormat(
    decimalPlaces > 3 ? UI_DEFAULT_DECIMAL_PLACES : decimalPlaces
  )
}

export const getSpotMarketOrder = async (
  market: UiBaseSpotMarket | undefined,
  order: Record<string, any>
): Promise<Order | undefined> => {
  if (!market) {
    return undefined
  }

  const { decimals: baseDecimal, symbol: baseSymbol } =
    ((await denomClient.getDenomToken(market.baseDenom)) as Token) ||
    market.baseDenom
  const { decimals: quoteDecimal, symbol: quoteSymbol } =
    ((await denomClient.getDenomToken(market.quoteDenom)) as Token) ||
    market.quoteDenom

  return {
    marketId: order.market_id,
    subaccountId: order.order_info.subaccount_id,
    feeRecipient: order.order_info.fee_recipient,
    price: formatNumber(
      new BigNumberInBase(
        new BigNumberInBase(order.order_info.price).toWei(
          baseDecimal - quoteDecimal
        )
      )
    ),
    amount: formatNumber(
      new BigNumberInWei(order.order_info.quantity).toBase(baseDecimal)
    ),
    orderType: order.order_type,
    margin: order.margin,
    triggerPrice: order.trigger_price,
    baseSymbol: baseSymbol.toUpperCase(),
    quoteSymbol: quoteSymbol.toUpperCase(),
    ticker: market.ticker
  }
}

export const getDerivativeOrder = async (
  market: PerpetualMarket | ExpiryFuturesMarket | undefined,
  order: Record<string, any>
): Promise<Order | undefined> => {
  if (!market) {
    return undefined
  }

  const { decimals: quoteDecimal, symbol } =
    ((await denomClient.getDenomToken(market.quoteDenom)) as Token) ||
    market.quoteDenom

  return {
    marketId: order.market_id,
    subaccountId: order.order_info.subaccount_id,
    feeRecipient: order.order_info.fee_recipient,
    price: formatNumber(
      new BigNumberInWei(order.order_info.price).toBase(quoteDecimal)
    ),
    amount: formatNumber(new BigNumberInBase(order.order_info.quantity)),
    orderType: order.order_type,
    margin: order.margin,
    triggerPrice: order.trigger_price,
    baseSymbol: market.oracleBase.toUpperCase(),
    quoteSymbol: symbol.toUpperCase(),
    ticker: market.ticker
  }
}

export const getCancelledOrder = (
  market:
    | PerpetualMarket
    | ExpiryFuturesMarket
    | UiBaseSpotMarketWithToken
    | undefined,
  order: Record<string, any>
): CancelledOrder | undefined => {
  if (!market) {
    return undefined
  }

  return {
    ticker: market.ticker,
    orderHash: order.order_hash
  }
}

export const getAllCancelledOrder = (
  market:
    | PerpetualMarket
    | ExpiryFuturesMarket
    | UiBaseSpotMarketWithToken
    | undefined
): AllCancelledOrder | undefined => {
  if (!market) {
    return undefined
  }

  return {
    ticker: market.ticker
  }
}
