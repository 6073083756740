import { defineStore } from 'pinia'
import { ExplorerTransaction } from '@injectivelabs/sdk-ts'
import { HttpRequestException } from '@injectivelabs/exceptions'
import { indexerRestExplorerApi } from '@/app/Services'
import { TransactionNotFoundException } from '@/app/exceptions/TransactionNotFoundException'
import { IndexerRestExplorerTransformer } from '@/app/client/transformers'
import { EventLogWithMessageType } from '@/types'
import { UIEventLogTransformer } from '@/app/client/transformers/UiEventLogTransformer'

type TransactionStoreState = {
  transaction?: ExplorerTransaction
  eventLogs?: EventLogWithMessageType[]
}

const initialStateFactory = () => ({
  transaction: undefined,
  eventLogs: undefined
})

export const useTransactionStore = defineStore('transaction', {
  state: (): TransactionStoreState => initialStateFactory(),
  actions: {
    async fetchTransaction(transactionHash: string) {
      const transactionStore = useTransactionStore()
      const appStore = useAppStore()

      try {
        const transaction = await indexerRestExplorerApi.fetchTransaction(
          transactionHash
        )

        transactionStore.$patch({
          transaction:
            await IndexerRestExplorerTransformer.singleTransactionToTransaction(
              transaction
            ),
          eventLogs: UIEventLogTransformer.transformLog(transaction.logs)
        })
      } catch (e: any) {
        if (e instanceof HttpRequestException) {
          if (e.code === 404 || e.message.includes('object not found')) {
            appStore.$patch({
              searchParam: transactionHash
            })

            throw new TransactionNotFoundException()
          }
        }

        throw e
      }
    },

    reset() {
      useTransactionStore().$reset()
    }
  }
})
