import BaseMessageParser from './BaseMessageParser'
import { MsgLiquidatePositionParsed, MsgBase } from '@/types/messages'
import { indexerDerivativesApi } from '@/app/Services'

export class MsgLiquidatePosition extends BaseMessageParser {
  async parse(): Promise<MsgLiquidatePositionParsed | MsgBase> {
    const { message } = this
    const { sender, market_id: marketId, subaccount_id: subaccount } = message

    const market = await indexerDerivativesApi.fetchMarket(marketId)

    if (!market) {
      return {
        sender,
        noSummary: true
      }
    }

    return Promise.resolve({
      sender,
      subaccount,
      ticker: market.ticker
    })
  }
}
