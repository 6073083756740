import BaseMessageParser from './BaseMessageParser'
import { MsgVoteParsed } from '@/types/messages'

export class MsgVote extends BaseMessageParser {
  parse(): Promise<MsgVoteParsed> {
    const { message } = this
    const { option: optionRaw, proposal_id: proposalId, voter } = message

    // VOTE_OPTION_NO_WITH_VETO
    let option = 'noWithVeto'

    if (optionRaw === 'VOTE_OPTION_YES') {
      option = 'yes'
    }

    if (optionRaw === 'VOTE_OPTION_ABSTAIN') {
      option = 'abstain'
    }

    if (optionRaw === 'VOTE_OPTION_NO') {
      option = 'no'
    }

    return Promise.resolve({
      voter,
      option,
      proposalId
    })
  }
}
