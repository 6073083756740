import BaseMessageParser from './message/BaseMessageParser'
import {
  DefaultMessage,
  MsgBatchCancelDerivativeOrders,
  MsgBatchCancelSpotOrders,
  MsgBatchCreateDerivativeLimitOrders,
  MsgBatchCreateSpotLimitOrders,
  MsgBatchUpdateOrders,
  MsgBeginRedelegate,
  MsgBid,
  MsgCancelDerivativeOrder,
  MsgCancelSpotOrder,
  MsgCreateDerivativeLimitOrder,
  MsgCreateDerivativeMarketOrder,
  MsgCreateSpotLimitOrder,
  MsgCreateSpotMarketOrder,
  MsgCreateInsuranceFund,
  MsgCreateValidator,
  MsgConfirmBatch,
  MsgDelegate,
  MsgDeposit,
  MsgDepositClaim,
  MsgEditValidator,
  MsgExternalTransfer,
  MsgGovDeposit,
  MsgIncreasePositionMargin,
  MsgInstantSpotMarketLaunch,
  MsgLiquidatePosition,
  MsgRecvPacket,
  MsgRequestBatch,
  MsgRequestRedemption,
  MsgSend,
  MsgMultiSend,
  MsgSendToEth,
  MsgSetOrchestratorAddresses,
  MsgSubaccountTransfer,
  MsgSubmitProposal,
  MsgTransfer,
  MsgTimeout,
  MsgUndelegate,
  MsgUnderwrite,
  MsgUnjail,
  MsgUpdateClient,
  MsgValsetConfirm,
  MsgVote,
  MsgWithdraw,
  MsgWithdrawDelegatorReward
} from './message'

export default class MessageParserFactory {
  static make(type: string, message: any): BaseMessageParser {
    switch (type) {
      // bank
      case '/cosmos.bank.v1beta1.MsgSend':
        return new MsgSend(message, type)
      case '/cosmos.bank.v1beta1.MsgMultiSend':
        return new MsgMultiSend(message, type)

      // ibc
      case '/ibc.applications.transfer.v1.MsgTransfer':
        return new MsgTransfer(message, type)
      case '/ibc.core.channel.v1.MsgRecvPacket':
        return new MsgRecvPacket(message, type)
      case '/ibc.core.client.v1.MsgUpdateClient':
        return new MsgUpdateClient(message, type)
      case '/ibc.core.channel.v1.MsgTimeout':
        return new MsgTimeout(message, type)

      // exchange
      case '/injective.exchange.v1beta1.MsgBatchCancelDerivativeOrders':
        return new MsgBatchCancelDerivativeOrders(message, type)
      case '/injective.exchange.v1beta1.MsgBatchCancelSpotOrders':
        return new MsgBatchCancelSpotOrders(message, type)
      case '/injective.exchange.v1beta1.MsgBatchCreateDerivativeLimitOrders':
        return new MsgBatchCreateDerivativeLimitOrders(message, type)
      case '/injective.exchange.v1beta1.MsgBatchCreateSpotLimitOrders':
        return new MsgBatchCreateSpotLimitOrders(message, type)
      case '/injective.exchange.v1beta1.MsgBatchUpdateOrders':
        return new MsgBatchUpdateOrders(message, type)
      case '/injective.exchange.v1beta1.MsgCancelDerivativeOrder':
        return new MsgCancelDerivativeOrder(message, type)
      case '/injective.exchange.v1beta1.MsgCancelSpotOrder':
        return new MsgCancelSpotOrder(message, type)
      case '/injective.exchange.v1beta1.MsgCreateDerivativeMarketOrder':
        return new MsgCreateDerivativeMarketOrder(message, type)
      case '/injective.exchange.v1beta1.MsgCreateDerivativeLimitOrder':
        return new MsgCreateDerivativeLimitOrder(message, type)
      case '/injective.exchange.v1beta1.MsgCreateSpotLimitOrder':
        return new MsgCreateSpotLimitOrder(message, type)
      case '/injective.exchange.v1beta1.MsgCreateSpotMarketOrder':
        return new MsgCreateSpotMarketOrder(message, type)
      case '/injective.exchange.v1beta1.MsgDeposit':
        return new MsgDeposit(message, type)
      case '/injective.exchange.v1beta1.MsgExternalTransfer':
        return new MsgExternalTransfer(message, type)
      case '/injective.exchange.v1beta1.MsgIncreasePositionMargin':
        return new MsgIncreasePositionMargin(message, type)
      case '/injective.exchange.v1beta1.MsgLiquidatePosition':
        return new MsgLiquidatePosition(message, type)
      case '/injective.exchange.v1beta1.MsgInstantSpotMarketLaunch':
        return new MsgInstantSpotMarketLaunch(message, type)
      case '/injective.exchange.v1beta1.MsgSubaccountTransfer':
        return new MsgSubaccountTransfer(message, type)
      case '/injective.exchange.v1beta1.MsgWithdraw':
        return new MsgWithdraw(message, type)

      // staking
      case '/cosmos.staking.v1beta1.MsgBeginRedelegate':
        return new MsgBeginRedelegate(message, type)
      case '/cosmos.staking.v1beta1.MsgCreateValidator':
        return new MsgCreateValidator(message, type)
      case '/cosmos.staking.v1beta1.MsgDelegate':
        return new MsgDelegate(message, type)
      case '/cosmos.staking.v1beta1.MsgEditValidator':
        return new MsgEditValidator(message, type)
      case '/cosmos.staking.v1beta1.MsgUndelegate':
        return new MsgUndelegate(message, type)

      // distribution
      case '/cosmos.distribution.v1beta1.MsgWithdrawDelegatorReward':
        return new MsgWithdrawDelegatorReward(message, type)

      // gov
      case '/cosmos.gov.v1beta1.MsgDeposit':
        return new MsgGovDeposit(message, type)
      case '/cosmos.gov.v1beta1.MsgSubmitProposal':
        return new MsgSubmitProposal(message, type)
      case '/cosmos.gov.v1beta1.MsgVote':
        return new MsgVote(message, type)

      // peggy
      case '/injective.peggy.v1.MsgConfirmBatch':
        return new MsgConfirmBatch(message, type)
      case '/injective.peggy.v1.MsgDepositClaim':
        return new MsgDepositClaim(message, type)
      case '/injective.peggy.v1.MsgRequestBatch':
        return new MsgRequestBatch(message, type)
      case '/injective.peggy.v1.MsgSendToEth':
        return new MsgSendToEth(message, type)
      case '/injective.peggy.v1.MsgValsetConfirm':
        return new MsgValsetConfirm(message, type)
      case '/injective.peggy.v1.MsgSetOrchestratorAddresses':
        return new MsgSetOrchestratorAddresses(message, type)

      // insurance
      case '/injective.insurance.v1beta1.MsgCreateInsuranceFund':
        return new MsgCreateInsuranceFund(message, type)
      case '/injective.insurance.v1beta1.MsgRequestRedemption':
        return new MsgRequestRedemption(message, type)
      case '/injective.insurance.v1beta1.MsgUnderwrite':
        return new MsgUnderwrite(message, type)

      // slashing
      case '/cosmos.slashing.v1beta1.MsgUnjail':
        return new MsgUnjail(message, type)

      // auction
      case '/injective.auction.v1beta1.MsgBid':
        return new MsgBid(message, type)
      default:
        return new DefaultMessage(message, type)
    }
  }
}
