import { awaitAll, BigNumberInWei } from '@injectivelabs/utils'
import { Coin } from '@injectivelabs/sdk-ts'
import BaseMessageParser from './BaseMessageParser'
import { formatNumber } from './helper'
import { MsgMultiSendParsed } from '@/types/messages'
import { denomClient } from '@/app/Services'

export class MsgMultiSend extends BaseMessageParser {
  async parse(): Promise<MsgMultiSendParsed> {
    const { message } = this
    const { inputs, outputs } = message

    const senders = await awaitAll(
      inputs,
      async (item: { address: string; coins: Coin[] }) => {
        const [entry] = item.coins
        const token = await denomClient.getDenomToken(entry.denom)

        return {
          address: item.address,
          amount: formatNumber(
            new BigNumberInWei(entry.amount || 0).toBase(
              token ? token.decimals : 18
            )
          ),
          symbol: token?.symbol || ''
        }
      }
    )

    const receivers = await awaitAll(
      outputs,
      async (item: { address: string; coins: Coin[] }) => {
        const [entry] = item.coins as { denom: string; amount: string }[]
        const token = await denomClient.getDenomToken(entry.denom)

        return {
          address: item.address,
          amount: formatNumber(
            new BigNumberInWei(entry.amount || 0).toBase(
              token ? token.decimals : 18
            )
          ),
          symbol: token?.symbol || ''
        }
      }
    )

    return Promise.resolve({
      senders,
      receivers
    })
  }
}
