import BaseMessageParser from './BaseMessageParser'
import { MsgCancelSpotOrderParsed } from '@/types/messages'
import { indexerSpotApi } from '@/app/Services'

export class MsgCancelSpotOrder extends BaseMessageParser {
  async parse(): Promise<MsgCancelSpotOrderParsed> {
    const { message } = this
    const market = await indexerSpotApi.fetchMarket(message.market_id)

    return {
      sender: message.sender,
      ticker: market.ticker,
      orderHash: message.order_hash
    }
  }
}
