import { BigNumberInWei } from '@injectivelabs/utils'
import { Token } from '@injectivelabs/token-metadata'
import BaseMessageParser from './BaseMessageParser'
import { formatNumber } from './helper'
import { MsgIncreasePositionMarginParsed } from '@/types/messages'
import { indexerDerivativesApi, denomClient } from '@/app/Services'

export class MsgIncreasePositionMargin extends BaseMessageParser {
  async parse(): Promise<MsgIncreasePositionMarginParsed> {
    const { message } = this

    const market = await indexerDerivativesApi.fetchMarket(message.market_id)

    const { decimals, symbol } =
      ((await denomClient.getDenomToken(market.quoteDenom)) as Token) ||
      market.quoteDenom

    return {
      symbol,
      sender: message.sender,
      amount: formatNumber(new BigNumberInWei(message.amount).toBase(decimals)),
      from: message.source_subaccount_id,
      to: message.destination_subaccount_id,
      ticker: market.ticker
    }
  }
}
