import { PerpetualMarket, ExpiryFuturesMarket } from '@injectivelabs/sdk-ts'
import {
  UiBaseSpotMarketWithToken,
  UiBasePerpetualMarket
} from '@injectivelabs/sdk-ui-ts'
import BaseMessageParser from './BaseMessageParser'
import {
  getCancelledOrder,
  getAllCancelledOrder,
  getDerivativeOrder,
  getSpotMarketOrder
} from './helper'
import {
  MsgBatchUpdateOrdersParsed,
  CancelledOrder,
  AllCancelledOrder,
  Order
} from '@/types/messages'
import {
  indexerDerivativesApi,
  indexerSpotApi,
  tokenService
} from '@/app/Services'

const fetchDerivativeMarketFn = async (
  marketId: string
): Promise<PerpetualMarket | ExpiryFuturesMarket | undefined> => {
  try {
    const market = (await indexerDerivativesApi.fetchMarket(
      marketId
    )) as UiBasePerpetualMarket

    if (!market) {
      return undefined
    }

    const marketWithToken = (await tokenService.toDerivativeMarketWithToken(
      market
    )) as PerpetualMarket | ExpiryFuturesMarket

    return marketWithToken
  } catch {
    return undefined
  }
}

const fetchSpotMarketFn = async (
  marketId: string
): Promise<UiBaseSpotMarketWithToken | undefined> => {
  try {
    const market = await indexerSpotApi.fetchMarket(marketId)

    if (!market) {
      return undefined
    }

    return await tokenService.toSpotMarketWithToken(market)
  } catch {
    return undefined
  }
}

export class MsgBatchUpdateOrders extends BaseMessageParser {
  async parse(): Promise<MsgBatchUpdateOrdersParsed> {
    const { message } = this

    const {
      sender,
      derivative_market_ids_to_cancel_all: rawAllCancelledDerivativeOrders,
      spot_market_ids_to_cancel_all: rawAllCancelledSpotOrders,
      derivative_orders_to_cancel: rawCancelledDerivativeOrders,
      spot_orders_to_cancel: rawCancelledSpotOrders,
      derivative_orders_to_create: rawDerivativeOrders,
      spot_orders_to_create: rawSpotOrders
    } = message

    let allCancelledDerivativeOrders = [] as AllCancelledOrder[]

    allCancelledDerivativeOrders = (
      await Promise.all(
        rawAllCancelledDerivativeOrders.map(async (marketId: string) =>
          getAllCancelledOrder(await fetchDerivativeMarketFn(marketId))
        )
      )
    ).filter((order) => order) as AllCancelledOrder[]

    let allCancelledSpotOrders = [] as AllCancelledOrder[]

    allCancelledSpotOrders = (
      await Promise.all(
        rawAllCancelledSpotOrders.map(async (marketId: string) =>
          getAllCancelledOrder(await fetchSpotMarketFn(marketId))
        )
      )
    ).filter((order) => order) as AllCancelledOrder[]

    const cancelledDerivativeOrders = (
      await Promise.all(
        rawCancelledDerivativeOrders.map(async (order: Record<string, any>) =>
          getCancelledOrder(
            await fetchDerivativeMarketFn(order.market_id),
            order
          )
        )
      )
    ).filter((order) => order) as CancelledOrder[]

    const cancelledSpotOrders = (
      await Promise.all(
        rawCancelledSpotOrders.map(async (order: Record<string, any>) =>
          getCancelledOrder(await fetchSpotMarketFn(order.market_id), order)
        )
      )
    ).filter((order) => order) as CancelledOrder[]

    const derivativeOrders = (
      await Promise.all(
        rawDerivativeOrders.map(async (order: Record<string, any>) =>
          getDerivativeOrder(
            await fetchDerivativeMarketFn(order.market_id),
            order
          )
        )
      )
    ).filter((order) => order) as Order[]

    const spotOrders = (
      await Promise.all(
        rawSpotOrders.map(async (order: Record<string, any>) =>
          getSpotMarketOrder(await fetchSpotMarketFn(order.market_id), order)
        )
      )
    ).filter((order) => order) as Order[]

    const noSummary =
      allCancelledDerivativeOrders.length === 0 &&
      allCancelledSpotOrders.length === 0 &&
      cancelledDerivativeOrders.length === 0 &&
      cancelledSpotOrders.length === 0 &&
      derivativeOrders.length === 0 &&
      spotOrders.length === 0

    return {
      sender,
      noSummary,
      allCancelledDerivativeOrders,
      allCancelledSpotOrders,
      cancelledDerivativeOrders,
      cancelledSpotOrders,
      derivativeOrders,
      spotOrders
    }
  }
}
