import BaseMessageParser from './BaseMessageParser'
import { MsgSetOrchestratorAddressesParsed } from '@/types/messages'

export class MsgSetOrchestratorAddresses extends BaseMessageParser {
  parse(): Promise<MsgSetOrchestratorAddressesParsed> {
    const { message } = this
    const { orchestrator, sender } = message

    return Promise.resolve({
      sender,
      orchestrator
    })
  }
}
