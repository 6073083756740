import BaseMessageParser from './BaseMessageParser'
import { MsgConfirmBatchParsed } from '@/types/messages'

export class MsgConfirmBatch extends BaseMessageParser {
  parse(): Promise<MsgConfirmBatchParsed> {
    const { message } = this
    const { orchestrator } = message

    return Promise.resolve({
      orchestrator
    })
  }
}
