import { UiStakingTransformer } from '@injectivelabs/sdk-ui-ts'
import BaseMessageParser from './BaseMessageParser'
import { MsgWithdrawDelegatorRewardParsed } from '@/types/messages'
import { stakingApi } from '@/app/Services'

export class MsgWithdrawDelegatorReward extends BaseMessageParser {
  async parse(): Promise<MsgWithdrawDelegatorRewardParsed> {
    const { message } = this

    const validator = await stakingApi.fetchValidator(message.validator_address)
    const [uiValidator] = UiStakingTransformer.validatorsToUiValidators([
      validator
    ])

    return Promise.resolve({
      sender: message.delegator_address,
      validatorAddress: message.validator_address,
      validator: uiValidator.name
    })
  }
}
