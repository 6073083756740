import BaseMessageParser from './BaseMessageParser'
import { MsgInstantSpotMarketLaunchParsed } from '@/types/messages'

export class MsgInstantSpotMarketLaunch extends BaseMessageParser {
  parse(): Promise<MsgInstantSpotMarketLaunchParsed> {
    const { message } = this
    const { sender, ticker } = message

    return Promise.resolve({
      sender,
      ticker
    })
  }
}
