import BaseMessageParser from './BaseMessageParser'
import { MsgCancelDerivativeOrderParsed } from '@/types/messages'
import { indexerDerivativesApi } from '@/app/Services'

export class MsgCancelDerivativeOrder extends BaseMessageParser {
  async parse(): Promise<MsgCancelDerivativeOrderParsed> {
    const { message } = this
    const market = await indexerDerivativesApi.fetchMarket(message.market_id)

    return {
      sender: message.sender,
      ticker: market.ticker,
      orderHash: message.order_hash
    }
  }
}
