import { EventLog } from '@injectivelabs/sdk-ts'
import { EventLogWithMessageType } from '@/types'

export class UIEventLogTransformer {
  static transformLog(logs?: EventLog[]): EventLogWithMessageType[] {
    const MESSAGE = 'message'
    const ACTION = 'action'

    if (!logs) {
      return [
        {
          messageType: '',
          events: []
        }
      ]
    }

    return logs.reduce((acc: EventLogWithMessageType[], log: EventLog) => {
      const message = log.events.find(({ type }) => type === MESSAGE)
      const messageType =
        message?.attributes?.find(({ key }) => key === ACTION)?.value || ''

      const formattedMessageType = messageType.split('.').pop() || ''

      return [...acc, { messageType: formattedMessageType, events: log.events }]
    }, [] as EventLogWithMessageType[])
  }
}
