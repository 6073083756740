import { PerpetualMarket, ExpiryFuturesMarket } from '@injectivelabs/sdk-ts'
import BaseMessageParser from './BaseMessageParser'
import { getDerivativeOrder } from './helper'
import { MsgCreateDerivativeLimitOrderParsed, Order } from '@/types/messages'
import { indexerDerivativesApi } from '@/app/Services'

export class MsgCreateDerivativeLimitOrder extends BaseMessageParser {
  async parse(): Promise<MsgCreateDerivativeLimitOrderParsed> {
    const { message } = this
    const market = (await indexerDerivativesApi.fetchMarket(
      message.order.market_id
    )) as PerpetualMarket | ExpiryFuturesMarket

    return {
      sender: message.sender,
      order: (await getDerivativeOrder(market, message.order)) as Order
    }
  }
}
