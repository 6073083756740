import { BigNumberInWei } from '@injectivelabs/utils'
import BaseMessageParser from './BaseMessageParser'
import { formatNumber } from './helper'
import { MsgSendParsed } from '@/types/messages'
import { denomClient } from '@/app/Services'

export class MsgSendToEth extends BaseMessageParser {
  async parse(): Promise<MsgSendParsed> {
    const { message } = this
    const { amount, sender, eth_dest: receiver } = message

    const tokenAmount = amount as { denom: string; amount: string }
    const token = await denomClient.getDenomToken(tokenAmount.denom)

    return Promise.resolve({
      sender,
      amount: formatNumber(
        new BigNumberInWei(tokenAmount.amount || 0).toBase(
          token ? token.decimals : 18
        )
      ),
      receiver,
      symbol: token ? token.symbol : ''
    })
  }
}
