import BaseMessageParser from './BaseMessageParser'
import { MsgEditValidatorParsed } from '@/types/messages'

export class MsgEditValidator extends BaseMessageParser {
  parse(): Promise<MsgEditValidatorParsed> {
    const { message } = this
    const {
      description: { moniker },
      validator_address: validator
    } = message

    return Promise.resolve({
      moniker,
      validator
    })
  }
}
