import BaseMessageParser from './BaseMessageParser'
import { getSpotMarketOrder } from './helper'
import { MsgCreateSpotLimitOrderParsed, Order } from '@/types/messages'
import { indexerSpotApi } from '@/app/Services'

export class MsgCreateSpotLimitOrder extends BaseMessageParser {
  async parse(): Promise<MsgCreateSpotLimitOrderParsed> {
    const { message } = this
    const market = await indexerSpotApi.fetchMarket(message.order.market_id)

    return {
      sender: message.sender,
      order: (await getSpotMarketOrder(market, message.order)) as Order
    }
  }
}
