import { PerpetualMarket, ExpiryFuturesMarket } from '@injectivelabs/sdk-ts'
import BaseMessageParser from './BaseMessageParser'
import { getDerivativeOrder } from './helper'
import {
  MsgBatchCreateDerivativeLimitOrdersParsed,
  Order
} from '@/types/messages'
import { indexerDerivativesApi } from '@/app/Services'

export class MsgBatchCreateDerivativeLimitOrders extends BaseMessageParser {
  async parse(): Promise<MsgBatchCreateDerivativeLimitOrdersParsed> {
    const { message } = this

    const markets = (await Promise.all(
      message.orders.map(
        async (order: Record<string, any>) =>
          await indexerDerivativesApi.fetchMarket(order.market_id)
      )
    )) as Array<PerpetualMarket | ExpiryFuturesMarket>

    const orders = (await Promise.all(
      message.orders.map(
        async (order: Record<string, any>, index: number) =>
          await getDerivativeOrder(markets[index], order)
      )
    )) as Order[]

    return {
      orders,
      sender: message.sender
    }
  }
}
