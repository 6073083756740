import BaseMessageParser from './BaseMessageParser'
import { MsgCreateValidatorParsed } from '@/types/messages'

export class MsgCreateValidator extends BaseMessageParser {
  parse(): Promise<MsgCreateValidatorParsed> {
    const { message } = this
    const {
      validator_address: validator,
      description: { moniker }
    } = message

    return Promise.resolve({
      moniker,
      validator
    })
  }
}
