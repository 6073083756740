import BaseMessageParser from './BaseMessageParser'
import { MsgValsetConfirmParsed } from '@/types/messages'

export class MsgValsetConfirm extends BaseMessageParser {
  parse(): Promise<MsgValsetConfirmParsed> {
    const { message } = this
    const { orchestrator } = message

    return Promise.resolve({
      orchestrator
    })
  }
}
