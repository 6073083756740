import { BigNumberInWei } from '@injectivelabs/utils'
import BaseMessageParser from './BaseMessageParser'
import { formatNumber } from './helper'
import { MsgSubmitProposalParsed } from '@/types/messages'
import { denomClient } from '@/app/Services'

export class MsgSubmitProposal extends BaseMessageParser {
  async parse(): Promise<MsgSubmitProposalParsed> {
    const { message } = this
    const { proposer, initial_deposit: amount } = message

    const [entry] = amount as { denom: string; amount: string }[]

    if (!entry) {
      return Promise.resolve({
        proposer,
        amount: '',
        symbol: ''
      })
    }

    const token = await denomClient.getDenomToken(entry.denom)

    return Promise.resolve({
      proposer,
      amount: formatNumber(
        new BigNumberInWei(entry.amount || 0).toBase(
          token ? token.decimals : 18
        )
      ),
      symbol: token ? token.symbol : ''
    })
  }
}
