import { BigNumberInWei } from '@injectivelabs/utils'
import BaseMessageParser from './BaseMessageParser'
import { formatNumber } from './helper'
import { MsgSubaccountTransferParsed } from '@/types/messages'
import { denomClient } from '@/app/Services'

export class MsgSubaccountTransfer extends BaseMessageParser {
  async parse(): Promise<MsgSubaccountTransferParsed> {
    const { message } = this
    const token = await denomClient.getDenomToken(message.amount.denom)

    return Promise.resolve({
      sender: message.sender,
      amount: formatNumber(
        new BigNumberInWei(message.amount.amount || 0).toBase(
          token ? token.decimals : 18
        )
      ),
      fromSubaccount: message.source_subaccount_id,
      toSubaccount: message.destination_subaccount_id,
      symbol: token ? token.symbol : ''
    })
  }
}
