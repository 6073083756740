import { SpotMarket } from '@injectivelabs/sdk-ts'
import BaseMessageParser from './BaseMessageParser'
import { getSpotMarketOrder } from './helper'
import { MsgBatchCreateSpotLimitOrdersParsed, Order } from '@/types/messages'
import { indexerSpotApi } from '@/app/Services'

export class MsgBatchCreateSpotLimitOrders extends BaseMessageParser {
  async parse(): Promise<MsgBatchCreateSpotLimitOrdersParsed> {
    const { message } = this

    const markets = (await Promise.all(
      message.orders.map(
        async (order: Record<string, any>) =>
          await indexerSpotApi.fetchMarket(order.market_id)
      )
    )) as SpotMarket[]

    const orders = (await Promise.all(
      message.orders.map(
        async (order: Record<string, any>, index: number) =>
          await getSpotMarketOrder(markets[index], order)
      )
    )) as Order[]

    return {
      orders,
      sender: message.sender
    }
  }
}
