import { BigNumberInWei } from '@injectivelabs/utils'
import BaseMessageParser from './BaseMessageParser'
import { formatNumber } from './helper'
import { MsgDepositParsed } from '@/types/messages'
import { denomClient } from '@/app/Services'

export class MsgDeposit extends BaseMessageParser {
  async parse(): Promise<MsgDepositParsed> {
    const { message } = this
    const {
      amount: { amount, denom },
      subaccount_id: subaccount,
      sender
    } = message

    const token = await denomClient.getDenomToken(denom)

    return Promise.resolve({
      sender,
      subaccount,
      amount: formatNumber(
        new BigNumberInWei(amount || 0).toBase(token ? token.decimals : 18)
      ),
      symbol: token ? token.symbol : ''
    })
  }
}
