import {
  IndexerRestExplorerTransformer as BaseIndexerRestExplorerTransformer,
  ExplorerTransaction
} from '@injectivelabs/sdk-ts'
import MessageParserFactory from '@/app/messages/MessageParserFactory'

export const singleTransactionToTransaction = async (
  transformedTransaction: ExplorerTransaction
): Promise<ExplorerTransaction> => {
  const messages = transformedTransaction.messages
  const parsedMessagesPromise = await Promise.all(
    messages.map((msg) =>
      MessageParserFactory.make(msg.type, msg.message).parse()
    )
  )

  const parsedMessages = messages.map((message, index) => {
    return {
      type: message.type,
      message: parsedMessagesPromise[index]
    }
  })

  return {
    ...transformedTransaction,
    parsedMessages
  }
}

export class IndexerRestExplorerTransformer extends BaseIndexerRestExplorerTransformer {
  static singleTransactionToTransaction = singleTransactionToTransaction
}
