import { BigNumberInWei } from '@injectivelabs/utils'
import { Web3Client } from '@injectivelabs/sdk-ui-ts'
import BaseMessageParser from './BaseMessageParser'
import { formatNumber } from './helper'
import { MsgDepositClaimParsed } from '@/types/messages'
import { denomClient } from '@/app/Services'
import { NETWORK, alchemyRpcEndpoint } from '@/app/utils/constants'

export class MsgDepositClaim extends BaseMessageParser {
  async parse(): Promise<MsgDepositClaimParsed> {
    const { message } = this
    const {
      amount,
      token_contract: denom,
      ethereum_sender: sender,
      cosmos_receiver: receiver
    } = message

    const token = await denomClient.getDenomToken(denom)

    if (token && token.symbol) {
      return Promise.resolve({
        receiver,
        sender,
        amount: formatNumber(
          new BigNumberInWei(amount || 0).toBase(token.decimals)
        ),
        symbol: token.symbol
      })
    }

    if (!alchemyRpcEndpoint) {
      return Promise.resolve({
        receiver,
        sender,
        amount: formatNumber(new BigNumberInWei(amount || 0).toBase(18)),
        symbol: ''
      })
    }

    const web3TokenMetadata = await new Web3Client({
      rpc: alchemyRpcEndpoint,
      network: NETWORK
    }).fetchTokenMetaData(denom)

    if (web3TokenMetadata && web3TokenMetadata.symbol) {
      return Promise.resolve({
        receiver,
        sender,
        amount: formatNumber(
          new BigNumberInWei(amount || 0).toBase(
            web3TokenMetadata.decimals || 18
          )
        ),
        symbol: web3TokenMetadata.symbol
      })
    }

    return Promise.resolve({
      receiver,
      sender,
      amount: formatNumber(new BigNumberInWei(amount || 0).toBase(18)),
      symbol: ''
    })
  }
}
