import BaseMessageParser from './BaseMessageParser'
import { MsgUpdateClientParsed } from '@/types/messages'

export class MsgUpdateClient extends BaseMessageParser {
  parse(): MsgUpdateClientParsed {
    const { message } = this

    const { signer } = message

    return {
      signer,
      noSummary: true
    }
  }
}
