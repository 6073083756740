export default abstract class BaseMessageParser {
  public message: any

  public type: string

  constructor(message: any, type: string) {
    this.message = message
    this.type = type
  }

  abstract parse(): any
}
