import { UiStakingTransformer } from '@injectivelabs/sdk-ui-ts'
import { BigNumberInWei } from '@injectivelabs/utils'
import BaseMessageParser from './BaseMessageParser'
import { formatNumber } from './helper'
import { MsgBeginRedelegateParsed } from '@/types/messages'
import { stakingApi, denomClient } from '@/app/Services'

export class MsgBeginRedelegate extends BaseMessageParser {
  async parse(): Promise<MsgBeginRedelegateParsed> {
    const { message } = this
    const {
      amount: { amount, denom },
      delegator_address: sender,
      validator_src_address: sourceValidatorAddress,
      validator_dst_address: destinationValidatorAddress
    } = message

    const token = await denomClient.getDenomToken(denom)
    const [sourceValidator, destinationValidator] = await Promise.all([
      stakingApi.fetchValidator(sourceValidatorAddress),
      stakingApi.fetchValidator(destinationValidatorAddress)
    ])

    const [uiSourceValidator] = UiStakingTransformer.validatorsToUiValidators([
      sourceValidator
    ])
    const [uiDestinationValidator] =
      UiStakingTransformer.validatorsToUiValidators([destinationValidator])

    return Promise.resolve({
      sender,
      sourceValidator: uiSourceValidator.name,
      destinationValidator: uiDestinationValidator.name,
      amount: formatNumber(
        new BigNumberInWei(amount || 0).toBase(token ? token.decimals : 18)
      ),
      symbol: token ? token.symbol : ''
    })
  }
}
