import { BigNumberInWei } from '@injectivelabs/utils'
import BaseMessageParser from './BaseMessageParser'
import { formatNumber } from './helper'
import { MsgBidParsed } from '@/types/messages'
import { denomClient } from '@/app/Services'

export class MsgBid extends BaseMessageParser {
  async parse(): Promise<MsgBidParsed> {
    const { message } = this
    const { bid_amount: bidAmount, sender, round } = message

    const { denom, amount } = bidAmount
    const token = await denomClient.getDenomToken(denom)

    return Promise.resolve({
      round,
      sender,
      amount: formatNumber(
        new BigNumberInWei(amount || 0).toBase(token ? token.decimals : 18)
      ),
      symbol: token ? token.symbol : ''
    })
  }
}
