import { BigNumberInWei } from '@injectivelabs/utils'
import BaseMessageParser from './BaseMessageParser'
import { formatNumber } from './helper'
import { MsgUnderwriteParsed, MsgBase } from '@/types/messages'
import { insuranceFundsApi, denomClient } from '@/app/Services'

export class MsgUnderwrite extends BaseMessageParser {
  async parse(): Promise<MsgUnderwriteParsed | MsgBase> {
    const { message } = this
    const {
      sender,
      market_id: marketId,
      deposit: { amount, denom }
    } = message

    const insuranceFund = await insuranceFundsApi.fetchInsuranceFund(marketId)

    if (!insuranceFund) {
      return Promise.resolve({
        sender,
        noSummary: true
      })
    }

    const token = await denomClient.getDenomToken(denom)

    return Promise.resolve({
      sender,
      ticker: insuranceFund.marketTicker,
      amount: formatNumber(
        new BigNumberInWei(amount || 0).toBase(token ? token.decimals : 18)
      ),
      symbol: token ? token.symbol : ''
    })
  }
}
